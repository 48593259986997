@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    scroll-behavior: smooth;

    scrollbar-width: thin;
    scrollbar-color: var(--gray-primary) #fff;
}

/* Width */
::-webkit-scrollbar {
    width: 0.3rem;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--gray-primary); 
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    --orange-primary: #FF9100;
    --orange-secondary: #FFC67B;
    --gray-primary: #4B5563;
    --orange-gradient: linear-gradient(107deg, var(--orange-primary) 8.18%, var(--orange-secondary) 100.03%);
    --gray-gradient: linear-gradient(254.53deg, #E9E9E9 4.88%, #CBCBCB 85.64%);
    --blue-gradient: linear-gradient(284.83deg, #0400BD -7.8%, #7000FF 99.63%);
    --base-shadow: 0px 2px 10px 0px rgba(178, 178, 178, 0.10);
}

@layer base {
    th,
    td{
        @apply py-2 px-4 text-[0.813rem] font-medium;
    }
    th{
        @apply font-semibold;
    }
    a{
        @apply no-underline;
    }
    input[type="checkbox"]:checked + label {
        @apply bg-black;
    }
    input[type="checkbox"]:checked + label:after {
        @apply opacity-100;
    }
    .blue-checkbox-container input[type="checkbox"] + label {
        @apply border-[#D6D6FF];
    }
    .blue-checkbox-container input[type="checkbox"]:checked + label {
        @apply bg-[#0400BD];
    }
    .blue-checkbox-container input[type="checkbox"]:checked + label:after {
        @apply opacity-100;
    }
    .grid-rows-10{
        display: grid;
        grid-template-rows: repeat(10, minmax(0, 1fr));
    }
    .wrapper-social-media-popup{
        width: calc(100vw - 3rem);
        height: calc(calc(100vw - 3rem) / 5);
    }
    .item-social-media-popup{
        width: calc(calc(100vw - 5rem) / 5);
        height: calc(calc(100vw - 5rem) / 5);
    }
    .wrapper-social-media-model{
        width: calc(100vw - 2rem);
        height: fit-content;
    }
    .item-social-media-model{
        width: calc(calc(100vw - 3.5rem) / 5);
        height: calc(calc(100vw - 10rem) / 5);
        padding: 1rem;
    }
    .w-header{
        width: calc(100vw - 14rem);
    }
    .wrapper-photo-model {
        width: 100%;
    }
}
